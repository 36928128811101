<template>
  <aCol class="pd-0" :span="24">
    <aTabs class="mt-10" type="card">
      <aTabPane key="data" forceRender>
        <div slot="tab">Dados</div>
        <aCol class="person-type" :span="12">
          <aFormItem class="mb-10">
            <a-radio-group
              :disabled="disableCustomerMainData"
              v-decorator="[
                'person_type',
                {
                  rules: [
                    {
                      required: requiredFields?.person_type ?? true,
                      message: 'Selecione qual o tipo de pessoa.',
                    },
                  ],
                },
              ]"
              :options="personTypes"
            />
          </aFormItem>

          <aFormItem class="mb-10 none">
            <a-input
              :disabled="disableCustomerMainData"
              class="travel-input"
              placeholder="ID"
              v-decorator="['id']"
            >
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="12" style="height: 40px">
          <a-checkbox
            v-model="isInternational"
            @change="onChangeIsInternational"
            class="f13 relative fw600 mb-20"
          >
            Cliente internacional
          </a-checkbox>

          <aFormItem class="none">
            <a-input v-decorator="['is_international']"> </a-input>
          </aFormItem>
        </aCol>

        <aCol class="mt-10" :span="24"></aCol>

        <aCol v-show="checkPersonType('Pessoa Jurídica')" :span="24">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Razão social </label>

            <a-input
              class="travel-input"
              placeholder="Razão social"
              v-decorator="[
                `company_name`,
                {
                  rules: [
                    {
                      required:
                        requiredFields?.company_name ??
                        checkPersonType('Pessoa Jurídica'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Jurídica')" :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Nome Fantasia </label>

            <a-input
              class="travel-input"
              placeholder="Nome Fantasia"
              v-decorator="[
                `trading_name`,
                {
                  rules: [
                    {
                      required:
                        requiredFields?.trading_name ??
                        checkPersonType('Pessoa Jurídica'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Jurídica')" :span="12">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> CNPJ </label>

            <a-input
              class="travel-input"
              placeholder="CNPJ"
              v-mask="'##.###.###/####-##'"
              v-decorator="[
                `cnpj`,
                {
                  rules: [
                    {
                      required:
                        requiredFields?.cnpj ??
                        checkPersonType('Pessoa Jurídica'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon slot="prefix" type="field-svg" />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Física')" :span="12">
          <aFormItem>
            <label for :class="'filled'"> Primeiro Nome </label>
            <a-input
              :disabled="disableCustomerMainData"
              class="travel-input"
              placeholder="Escreva..."
              v-decorator="[
                'first_name',
                {
                  rules: [
                    {
                      required:
                        requiredFields?.first_name ??
                        checkPersonType('Pessoa Física'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="!disableCustomerMainData"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Física')" :span="12">
          <aFormItem>
            <label for :class="'filled'"> Sobrenome </label>
            <a-input
              :disabled="disableCustomerMainData"
              class="travel-input"
              placeholder="Escreva..."
              v-decorator="[
                'last_name',
                {
                  rules: [
                    {
                      required:
                        requiredFields?.last_name ??
                        checkPersonType('Pessoa Física'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="!disableCustomerMainData"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="24">
          <aFormItem>
            <label for :class="'filled'"> E-mail </label>
            <a-input
              class="travel-input"
              placeholder="Escreva..."
              :disabled="disableCustomerMainData"
              v-decorator="[
                'email',
                {
                  rules: [
                    {
                      type: 'email',
                      message: 'Escreva um e-mail válido',
                    },
                    {
                      required: requiredFields?.email ?? true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="!disableCustomerMainData"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Física')" :span="12">
          <aFormItem>
            <label for :class="'filled'"> Nascimento </label>
            <a-date-picker
              class="travel-input"
              placeholder="Selecione"
              :disabled="$router.history.current.params.action === 'view'"
              format="DD/MM/YYYY"
              v-mask="'##/##/####'"
              :showToday="false"
              :disabled-date="adultHoodDate"
              @openChange="defaultDate"
              v-decorator="[
                'birthday',
                {
                  rules: [
                    {
                      required:
                        requiredFields?.birthday ??
                        checkPersonType('Pessoa Física'),
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
            </a-date-picker>
          </aFormItem>
        </aCol>

        <aCol v-show="checkPersonType('Pessoa Física')" :span="12">
          <aFormItem>
            <label for :class="'filled'"> CPF </label>
            <a-input
              :class="
                $route.name === 'edit' && $root.isAdmin() == false
                  ? 'readonly'
                  : ''
              "
              class="travel-input"
              placeholder="Escreva..."
              v-mask="
                !isInternational ? '###.###.###-##' : '####################'
              "
              @blur="verifyTypedCPF"
              v-decorator="[
                'cpf',
                {
                  rules: [
                    {
                      required:
                        requiredFields?.cpf ?? checkPersonType('Pessoa Física'),
                      min: 9,
                      message: 'Preencha todos números.',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="$router.history.current.params.action !== 'view'"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol
          v-show="!isInternational && checkPersonType('Pessoa Física')"
          :span="10"
        >
          <aFormItem>
            <label for :class="'filled'"> RG </label>
            <a-input
              class="travel-input"
              placeholder="Escreva..."
              :disabled="$router.history.current.params.action === 'view'"
              v-mask="[
                '##.###.###',
                '#.###.###',
                '###.###.###-##',
                '###.###.###.###-##',
              ]"
              v-decorator="[
                'rg',
                {
                  rules: [
                    {
                      required: requiredFields?.rg ?? false,
                      message: 'Obrigatório',
                    },
                    { min: 9, message: 'Preencha todos números' },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="$router.history.current.params.action !== 'view'"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol
          v-show="!isInternational && checkPersonType('Pessoa Física')"
          :span="8"
        >
          <aFormItem>
            <label for :class="'filled'"> Órgão Emissor </label>
            <a-input
              class="travel-input"
              :disabled="$router.history.current.params.action === 'view'"
              type="text"
              placeholder="Escreva..."
              v-decorator="[
                'rg_emissor',
                {
                  rules: [
                    {
                      required: requiredFields?.rg_emissor ?? false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="$router.history.current.params.action !== 'view'"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol
          v-show="!isInternational && checkPersonType('Pessoa Física')"
          :span="6"
        >
          <aFormItem>
            <label for :class="'filled'"> Estado de emissão </label>
            <a-select
              class="travel-input"
              :disabled="$router.history.current.params.action === 'view'"
              show-search
              placeholder="Selecione"
              :style="'width: 100%'"
              v-decorator="[
                'rg_state',
                {
                  rules: [
                    {
                      required: requiredFields?.rg_state ?? false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of states"
                :key="index"
                :value="item.initials"
              >
                {{ item.initials.toUpperCase() }}
              </a-select-option>
            </a-select>
          </aFormItem>
        </aCol>

        <aCol :span="5">
          <aFormItem class="travel-input-outer">
            <label for :class="'filled'"> Código país </label>
            <aSelect
              class="travel-input w100"
              :dropdownMatchSelectWidth="false"
              :filterOption="filterOption"
              @change="onChangeCountryCode"
              placeholder="Selecione"
              showSearch
              allowClear
              v-decorator="[
                'country_code_fake',
                {
                  initialValue: 'Brasil-55',
                  rules: [
                    {
                      required: true,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <aSelectOption
                v-for="(item, i) in countryCodes"
                :value="`${item.pais}-${item.ddi}`"
                :country="item.pais"
                :key="i"
              >
                <img width="20" :src="item.img" alt="flag" class="mr-5" />
                <b>+{{ item.ddi }}</b>
                <span class="ml-10 f11" style="color: #aaa">{{
                  item.pais
                }}</span>
              </aSelectOption>
            </aSelect>
          </aFormItem>

          <aFormItem class="none">
            <a-input
              class="travel-input"
              placeholder="Código país"
              v-decorator="['country_code', { initialValue: '55' }]"
            >
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="10">
          <aFormItem>
            <label for :class="'filled'"> Celular </label>
            <a-input
              class="travel-input"
              placeholder="Escreva..."
              :disabled="disableCustomerMainData"
              v-mask="
                !isInternational ? '(##) # ####-####' : '#####################'
              "
              v-decorator="[
                'mobile_phone',
                {
                  rules: [
                    {
                      required: requiredFields?.mobile_phone ?? true,
                      message: 'Obrigatório.',
                    },
                    {
                      required: requiredFields?.mobile_phone ?? true,
                      min: !isInternational ? 16 : 9,
                      message: 'Preencha todos números',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="!disableCustomerMainData"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="9">
          <aFormItem>
            <label for :class="'filled'"> Telefone fixo </label>
            <a-input
              class="travel-input"
              placeholder="Escreva..."
              v-mask="!isInternational ? '(##) ####-####' : '############'"
              :disabled="$router.history.current.params.action === 'view'"
              v-decorator="[
                'phone',
                {
                  rules: [
                    {
                      required: requiredFields?.phone ?? false,
                      message: 'Obrigatório',
                    },
                    {
                      required: requiredFields?.phone ?? false,
                      min: 14,
                      message: 'Preencha todos números',
                    },
                  ],
                },
              ]"
            >
              <a-icon
                v-if="$router.history.current.params.action !== 'view'"
                slot="prefix"
                type="field-svg"
              />
            </a-input>
          </aFormItem>
        </aCol>

        <aCol :span="10">
          <aFormItem>
            <label for :class="'filled'"> Status </label>
            <a-select
              class="travel-input"
              :disabled="$router.history.current.params.action === 'view'"
              show-search
              placeholder="Selecione"
              :style="'width: 100%'"
              v-decorator="[
                'status',
                {
                  initialValue: 'Ativo',
                  rules: [
                    {
                      required: requiredFields?.status ?? false,
                      message: 'Obrigatório',
                    },
                  ],
                },
              ]"
            >
              <a-select-option
                v-for="(item, index) of status"
                :key="index"
                :value="item.value"
              >
                {{ item.label }}
              </a-select-option>
            </a-select>
          </aFormItem>
        </aCol>
      </aTabPane>

      <aTabPane key="address" forceRender>
        <div slot="tab">Endereço</div>
        <div class="mt-10">
          <AddressData
            :form="customerForm"
            :requiredFields="requiredFields"
            :isInternational="isInternational"
            :fieldSizes="{
              cep: 10,
              location_street: 24,
              location_number: 8,
              location_complement: 16,
              location_neighborhood: 10,
              location_city: 10,
              location_state: 4,
              location_country: 10,
            }"
          />
        </div>
      </aTabPane>

      <aTabPane
        v-if="
          customerForm.getFieldValue('email') &&
          customerForm.getFieldValue('mobile_phone')
        "
        key="contacts"
        tab="Contatos"
        forceRender
      >
        <div class="mt-10">
          <a-row class="f10 header" :gutter="20">
            <aCol :span="14"> EMAILS </aCol>
            <aCol :span="5"> PRINCIPAL </aCol>
            <aCol :span="3"> </aCol>
          </a-row>

          <a-radio-group
            v-model="theMainEmail"
            @change="onSelectMainEmail"
            style="width: 100%"
          >
            <a-row
              class="extra-fields"
              v-for="(item, index) in extraEmailsRows"
              :key="index"
              :gutter="20"
              :class="item.id == 1 ? 'none' : ''"
            >
              <aCol :span="14">
                <aFormItem>
                  <a-input
                    class="travel-input"
                    :class="item.id == 1 ? 'readonly none' : ''"
                    placeholder="Escreva..."
                    @blur="verifyEmailAddress(item.id)"
                    v-decorator="[
                      `extra_email_${item.id}`,
                      {
                        initialValue: theEmailInitialValue(item.id),

                        rules: [
                          {
                            type: `email`,
                            message: 'Escreva um e-mail válido',
                          },
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon class="grayscale" type="mail" slot="prefix" />
                  </a-input>
                </aFormItem>
              </aCol>

              <aCol :class="item.id == 1 ? 'readonly none' : ''" :span="5">
                <a-radio :value="item.id"></a-radio>
              </aCol>

              <aCol :span="3">
                <a-button
                  v-if="item.id != 1 && item.id == extraEmailsRows.length"
                  class="delete"
                  size="small"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removeEmailRow(item.id)"
                >
                </a-button>
              </aCol>
            </a-row>
          </a-radio-group>

          <a-row class="mb-30">
            <aCol class="a-center" :span="24">
              <a-button
                class="add-button color f12"
                size="small"
                @click="addEmailRow"
              >
                Adicionar e-mail
              </a-button>
            </aCol>
          </a-row>

          <a-divider />

          <a-row class="f10 header" :gutter="20">
            <aCol :span="4"> TIPO </aCol>
            <aCol :span="9"> NÚMEROS </aCol>
            <aCol :span="5"> PRINCIPAL </aCol>
            <aCol :span="3"> </aCol>
          </a-row>

          <a-radio-group
            v-model="theMainPhone"
            @change="onSelectMainPhone"
            style="width: 100%"
          >
            <a-row
              class="extra-fields"
              v-for="(item, index) in extraPhonesRows"
              :key="index"
              :gutter="20"
              :class="thePhoneNumberClass(item.id)"
            >
              <aCol :span="4">
                <aFormItem>
                  <a-select
                    class="travel-input"
                    :class="thePhoneNumberClass(item.id)"
                    show-search
                    placeholder="Tel/Cel"
                    :style="'width: 100%'"
                    v-decorator="[
                      `extra_phone_type_${item.id}`,
                      {
                        initialValue: thePhoneTypeInitialValue(item.id),
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-select-option
                      v-for="(item, index) of phonesType"
                      :key="index"
                      :value="item.name"
                    >
                      <a-icon :type="item.ico" />
                    </a-select-option>
                  </a-select>
                </aFormItem>
              </aCol>

              <aCol :span="9">
                <aFormItem>
                  <a-input
                    class="travel-input"
                    :class="thePhoneNumberClass(item.id)"
                    placeholder="Escreva..."
                    v-mask="
                      customerForm.getFieldValue(
                        `extra_phone_type_${item.id}`
                      ) == 'Mobile'
                        ? '(##) # ####-####'
                        : '(##) ####-####'
                    "
                    @blur="verifyPhone(item.id)"
                    v-decorator="[
                      `extra_phone_${item.id}`,
                      {
                        initialValue: thePhoneNumberInitialValue(item.id),
                        rules: [
                          {
                            required: false,
                            message: 'Obrigatório',
                          },
                        ],
                      },
                    ]"
                  >
                    <a-icon slot="prefix" type="field-svg" />
                  </a-input>
                </aFormItem>
              </aCol>

              <aCol :span="5">
                <a-radio
                  :class="thePhoneNumberClass(item.id)"
                  :value="item.id"
                ></a-radio>
              </aCol>

              <aCol :span="3">
                <a-button
                  v-if="item.id != 1 && item.id == extraPhonesRows.length"
                  class="delete"
                  size="small"
                  type="danger"
                  shape="circle"
                  icon="delete"
                  @click="removePhoneRow(item.id)"
                >
                </a-button>
              </aCol>
            </a-row>
          </a-radio-group>

          <a-row class="mb-30">
            <aCol class="a-center" :span="24">
              <a-button
                class="add-button color f12"
                size="small"
                @click="addPhoneRow"
              >
                Adicionar telefone
              </a-button>
            </aCol>
          </a-row>
        </div>
      </aTabPane>
    </aTabs>
  </aCol>
</template>

<script>
import moment from "moment";
import subYears from "date-fns/subYears";
import { format } from "date-fns";

import countryCodes from "@/json/countryCodeList";
import AddressData from "../../general/AddressData";

moment.locale("pt-br");

export default {
  props: {
    customerForm: Object,
    form: Object,
    customer: Object,
    contract: Object,
    tempContract: Object,
    disableCustomerMainData: Boolean,
    requiredFields: Object,
  },
  components: { AddressData },
  data() {
    return {
      isInternational: false,
      countryCodes,
      theMainEmail: 1,
      theMainPhone: 1,
      phonesType: [
        {
          name: "Phone",
          ico: "phone",
        },
        {
          name: "Mobile",
          ico: "mobile",
        },
      ],
      extraEmailsRows: [{ id: 1 }],
      extraPhonesRows: [{ id: 1 }],
      states: [
        { name: "Acre", initials: "AC" },
        { name: "Alagoas", initials: "AL" },
        { name: "Amapá", initials: "AP" },
        { name: "Amazonas", initials: "AM" },
        { name: "Bahia", initials: "BA" },
        { name: "Ceará", initials: "CE" },
        { name: "Distrito Federal", initials: "DF" },
        { name: "Espírito Santo", initials: "ES" },
        { name: "Goiás", initials: "GO" },
        { name: "Maranhão", initials: "MA" },
        { name: "Mato Grosso", initials: "MT" },
        { name: "Mato Grosso do Sul", initials: "MS" },
        { name: "Minas Gerais", initials: "MG" },
        { name: "Pará", initials: "PA" },
        { name: "Paraíba", initials: "PB" },
        { name: "Paraná", initials: "PR" },
        { name: "Pernambuco", initials: "PE" },
        { name: "Piauí", initials: "PI" },
        { name: "Rio de Janeiro", initials: "RJ" },
        { name: "Rio Grande do Norte", initials: "RN" },
        { name: "Rio Grande do Sul", initials: "RS" },
        { name: "Rondônia", initials: "RO" },
        { name: "Roraima", initials: "RR" },
        { name: "Santa Catarina", initials: "SC" },
        { name: "São Paulo", initials: "SP" },
        { name: "Sergipe", initials: "SE" },
        { name: "Tocantins", initials: "TO" },
      ],
      status: [
        { label: "Ativo", value: "Ativo" },
        { label: "Desativado", value: "Desativado" },
      ],
      cepLoading: false,
      personTypes: [
        { label: "Pessoa Física", value: "Pessoa Física" },
        { label: "Pessoa Jurídica", value: "Pessoa Jurídica" },
      ],
    };
  },
  mounted() {
    if (this.customer.person_type == undefined) {
      this.customerForm.setFieldsValue({
        person_type: "Pessoa Física",
      });
    }

    this.customerForm.setFieldsValue(this.customer);

    this.isInternational = ["1", true].includes(this.customer.is_international)
      ? true
      : false;

    this.setCustomerFields();

    this.$on("setCustomerFields", this.setCustomerFields);
  },
  methods: {
    verifyTypedCPF(val) {
      // Remover caracteres especiais do CPF
      let cpf = val.target.value ? val.target.value.replace(/\D/g, "") : "";

      if (!this.isInternational) {
        if (!/^\d{11}$/.test(cpf)) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });
          return false;
        }

        if (/^(\d)\1{10}$/.test(cpf)) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });

          return false;
        }

        if (
          [
            "00000000000",
            "11111111111",
            "00011122233",
            "22222222222",
            "33333333333",
            "44444444444",
            "55555555555",
            "66666666666",
            "77777777777",
            "88888888888",
            "99999999999",
          ].includes(cpf)
        ) {
          this.customerForm.setFieldsValue({
            [`cpf`]: undefined,
          });

          return false;
        }
      }
      return true;
    },
    theEmailInitialValue(id) {
      let theValue = undefined;

      if (id == 1 && this.customer !== undefined) {
        theValue =
          this.customer.email !== undefined
            ? this.customer?.email
            : this.customerForm.getFieldValue("email");
      }

      return theValue;
    },
    setCustomerFields() {
      setTimeout(() => {
        this.extraEmailsRows = this.customer.extra_emails_rows
          ? JSON.parse(this.customer.extra_emails_rows)
          : [{ id: 1 }];

        this.extraPhonesRows = this.customer.extra_phones_rows
          ? JSON.parse(this.customer.extra_phones_rows)
          : this.customer.phone
          ? [{ id: 1 }, { id: 2 }]
          : [{ id: 1 }];

        this.theMainEmail = this.customer.main_email
          ? parseInt(this.customer.main_email)
          : 1;

        this.theMainPhone = this.customer.main_phone
          ? parseInt(this.customer.main_phone)
          : 1;

        setTimeout(() => {
          this.extraEmailsRows.forEach((row) => {
            if (row.id != 1) {
              this.customerForm.setFieldsValue({
                [`extra_email_${row.id}`]:
                  this.customer[`extra_email_${row.id}`],
              });
            }
          });

          this.extraPhonesRows.forEach((row) => {
            if (row.id != 1) {
              this.customerForm.setFieldsValue({
                [`extra_phone_${row.id}`]:
                  this.customer[`extra_phone_${row.id}`],
                [`extra_phone_type_${row.id}`]:
                  this.customer[`extra_phone_type_${row.id}`],
              });
            }

            if (row.id == 2 && this.customer.phone) {
              this.customerForm.setFieldsValue({
                [`extra_phone_${row.id}`]: this.customer.phone,
              });
            }
          });
        }, 100);
      }, 500);
    },
    thePhoneTypeInitialValue(id) {
      let theValue = undefined;
      if (id == 1) {
        theValue = "Mobile";
      }

      if (id == 2) {
        theValue =
          this.customer.phone || this.customerForm.getFieldValue("phone")
            ? "Phone"
            : undefined;
      }

      return theValue;
    },
    thePhoneNumberClass(id) {
      let theClass = "";
      if (id == 1) {
        theClass = "readonly none";
      }

      if (id == 2) {
        theClass =
          this.customer.phone || this.customerForm.getFieldValue("phone")
            ? "readonly none"
            : "";
      }

      return theClass;
    },
    thePhoneNumberInitialValue(id) {
      let theValue = undefined;
      if (id == 1 && this.customer !== undefined) {
        theValue = this.customer.mobile_phone
          ? this.customer.mobile_phone
          : this.customerForm.getFieldValue("mobile_phone");
      }

      if (id == 2) {
        theValue = this.customer.phone
          ? this.customer.phone
          : this.customerForm.getFieldValue("phone");
      }

      return theValue;
    },
    checkPersonType(personType) {
      return this.customerForm.getFieldValue("person_type") == personType
        ? true
        : false;
    },
    verifyEmailAddress(id) {
      let verifyData = {
        customer_id: this.customer.id,
        what_to_verify: "email",
        value_to_verify: this.customerForm.getFieldValue("extra_email_" + id),
      };

      this.$http
        .post("/customer/verifications", verifyData)
        .then(({ data }) => {
          data;
        })
        .catch(({ response }) => {
          response;
          this.$message.warning(response.data.message);
          this.customerForm.setFieldsValue({
            [`extra_email_${id}`]: undefined,
          });
        });
    },
    verifyPhone(id) {
      let verifyData = {
        customer_id: this.customer.id,
        what_to_verify: "phones",
        value_to_verify: this.customerForm.getFieldValue("extra_phone_" + id),
      };

      this.$http
        .post("/customer/verifications", verifyData)
        .then(({ data }) => {
          data;
        })
        .catch(({ response }) => {
          response;
          this.$message.warning(response.data.message);
          this.customerForm.setFieldsValue({
            [`extra_phone_${id}`]: undefined,
          });
        });
    },
    onSelectMainEmail(e) {
      this.customer.main_email = e.target.value;
    },
    onSelectMainPhone(e) {
      this.customer.main_phone = e.target.value;
    },
    addEmailRow() {
      this.extraEmailsRows.push({
        id: this.extraEmailsRows.length + 1,
      });

      this.customer.extra_emails_rows = JSON.stringify(this.extraEmailsRows);
    },
    removeEmailRow(id) {
      for (var i in this.extraEmailsRows) {
        if (this.extraEmailsRows[i].id == id) {
          this.extraEmailsRows.splice(i, 1);
          break;
        }
      }
      this.customer.extra_emails_rows = JSON.stringify(this.extraEmailsRows);
    },
    addPhoneRow() {
      this.extraPhonesRows.push({
        id: this.extraPhonesRows.length + 1,
      });

      this.customer.extra_phones_rows = JSON.stringify(this.extraPhonesRows);
    },
    onChangeIsInternational(e) {
      this.customerForm.setFieldsValue({
        is_international: e.target.checked,
      });
    },
    removePhoneRow(id) {
      for (var i in this.extraPhonesRows) {
        if (this.extraPhonesRows[i].id == id) {
          this.extraPhonesRows.splice(i, 1);
          break;
        }
      }
      this.customer.extra_phones_rows = JSON.stringify(this.extraPhonesRows);
    },
    defaultDate() {
      setTimeout(() => {
        this.customerForm.setFieldsValue({
          birthday: this.customerForm.getFieldValue("birthday")
            ? this.customerForm.getFieldValue("birthday")
            : format(subYears(new Date(), 18), "MM/dd/yyyy"),
        });
      }, 1);
    },
    adultHoodDate(current) {
      return current && current > subYears(new Date(), 18);
    },
    onChangeCountryCode(e) {
      this.customerForm.setFieldsValue({
        country_code: e ? e.split("-")[1] : "",
      });
    },
    filterOption(inputValue, option) {
      return option.data.attrs.country
        .toLowerCase()
        .startsWith(inputValue.toLowerCase());
    },
  },
};
</script>

<style lang="sass" scoped>
.extra-fields
  .delete
    position: relative
    top: 9px
  .ant-radio-wrapper
    position: relative
    top: 9px

.header
  font-weight: 600
.person-type
  height: 30px
  margin-top: -18px
  margin-bottom: 36px
</style>
